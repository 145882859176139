<template>
  <div>
    <h1 class="heading">Partner Payments</h1>
    <div style="margin-bottom: 15px;">
      <div>
        <div style="display: flex;flex-direction: column;">
          <div style="display: flex;">
            <div style="width: 200px;">
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">All standart slots: {{ allCountOfStandart }}</label>
            </div>
            <div>
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available standart slots: {{ countOfStandart }}</label>
            </div>
          </div>
          <div style="display:flex;">
            <div style="width: 200px;">
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">All profi slots: {{ allCountOfProfi }}</label>
            </div>
            <div>
              <label style="margin-bottom: 0;display: block;margin-right: 15px;">Available profi slots: {{ countOfProfi }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-data-table
          :headers="paymentsHeader"
          :items="payments"
          class="elevation-1"
          hide-default-footer
      >
        <template v-slot:body>

          <tbody v-if='payments.length'>
          <tr v-for="(item, index) in payments" :key="index">
            <td class="d-block d-sm-table-cell">{{item.hotel.name}} ({{ item.hotel.id }})</td>
            <td class="d-block d-sm-table-cell">{{ item.user.email }}</td>
            <td class="d-block d-sm-table-cell">{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</td>
            <td class="d-block d-sm-table-cell">{{ tariffNumeric[item.plan] }}</td>
            <td class="d-block d-sm-table-cell" style="text-transform: capitalize;">{{ item.type }}</td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">{{ $moment(item.updated_at).format('DD.MM.YYYY') }} - {{ $moment(item.end_date).format('DD.MM.YYYY') }}</td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
              no period
            </td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">
              {{item.plan===2?'70':'145'}} €
            </td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
              -
            </td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'card'">
              {{item.plan===2?(70*0.35):(145*0.35)}} €
            </td>
            <td class="d-block d-sm-table-cell" v-if="item.type === 'slot'">
              -
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td style="text-align: center" colspan="8">You don't have payments yet</td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
  import AgencyService from "../services/agency.service";
  import titleMixin from "../mixins/titleMixin";

  export default {
    mixins:[titleMixin],
    title(){
      return "Partner Payments"
    },
    name: "PaymentsPartners",
    data(){
      return{
        allSlotsCount:[],
        payments:[],
        availableSlots:[],
        tariffNumeric:{
          1:'Free',
          2:'Standart',
          3:'Profi'
        },
        paymentsHeader:[
          { text: "Hotel", align: "left", sortable: true },
          { text: "User email", align: "left", sortable: true },
          { text: "Date", align: "left", sortable: true },
          { text: "Tariff", align: "left", sortable: true },
          {text: "Type pay", align: "left", sortable: true},
          { text: "Period", align: "left", sortable: true },
          { text: "Summary", align: "left", sortable: true },
          { text: "Fee", align: "left", sortable: true },
        ],
      }
    },
    async mounted(){
      this.payments = await  AgencyService.getPayments();
      this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
      this.allSlotsCount = await AgencyService.getSlotsQuantity(this.$store.state.profile.id);
    },
    computed:{
        allCountOfStandart(){
          const candidate = this.allSlotsCount.find(x => x.plan_id === 2);
          if(candidate){
            return candidate.quantity
          }else{
            return 0
          }
        },
        allCountOfProfi(){
          const candidate = this.allSlotsCount.find(x => x.plan_id === 3);
          if(candidate){
            return candidate.quantity
          }else{
            return 0
          }
        },
        countOfStandart(){
          const candidate = this.availableSlots.find(x => x.plan_id === 2);
          if(candidate){
            return candidate.quantity
          }else{
            return 0
          }
        },
        countOfProfi(){
          const candidate = this.availableSlots.find(x => x.plan_id === 3);
          if(candidate){
            return candidate.quantity
          }else{
            return 0
          }
        },
    }
  }
</script>

<style scoped>

</style>
